/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-central-1',

  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_7swrJzesc',
  aws_user_pools_web_client_id: '9e26k9fc1adcoc918v7out01l',
}

export default awsmobile
