/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 06/12/2021.
 */
import { Suspense, useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { Header } from './components/header/Header'
import { SpinnerFullHeight } from './components/spinner/SpinnerFullHeight'
import { FrontPage } from './pages/frontpage/FrontPage'
import { LAST_AUTOLOGOUT_PAGE_EXPIRERY, LAST_AUTOLOGOUT_PAGE_KEY, LAST_AUTOLOGOUT_TIME_KEY, PROJECT_PAGE, STATS_PAGE } from './constants'
import { ProjectPage } from './pages/project/ProjectPage'
import { AssignmentPage } from './pages/assignment/AssignmentPage'
import { StatsPage } from './pages/stats/StatsPage'
import AutoLogOutModal from './components/modals/AutoLogOutModal'

export const Main = () => {

  const navigate = useNavigate()

  useEffect(() => {
    try {
      // if we were auto logged out lately, we want to return to the last page.
      const lastAutoLogOutTime: string | null = window.localStorage.getItem(LAST_AUTOLOGOUT_TIME_KEY)
      const lastTabPathName: string | null = window.localStorage.getItem(LAST_AUTOLOGOUT_PAGE_KEY)

      if (!lastAutoLogOutTime || !lastTabPathName) {
        return
      }

      if (Date.now() < Number(lastAutoLogOutTime) + LAST_AUTOLOGOUT_PAGE_EXPIRERY) {
        // auto-logged out lately. Should redirect to last page.
        navigate(lastTabPathName)

        window.localStorage.removeItem(LAST_AUTOLOGOUT_TIME_KEY)
        window.localStorage.removeItem(LAST_AUTOLOGOUT_PAGE_KEY)
      }
    } catch (err) {
      console.log('Wrapper >> maybe redirect to last active page on signin error:', err)
    }
  }, [navigate])

  return (
    <>
      <Header></Header>
      <AutoLogOutModal />
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<SpinnerFullHeight />}>
              <FrontPage></FrontPage>
            </Suspense>
          }
        />

        <Route
          path={PROJECT_PAGE.to}
          element={
            <Suspense fallback={<SpinnerFullHeight />}>
              <ProjectPage></ProjectPage>
            </Suspense>
          }
        />

        <Route
          path={STATS_PAGE.to}
          element={
            <Suspense fallback={<SpinnerFullHeight />}>
              <StatsPage></StatsPage>
            </Suspense>
          }
        />

        <Route
          path={`/assignment/:projectId/*`}
          element={
            <Suspense fallback={<SpinnerFullHeight />}>
              <AssignmentPage></AssignmentPage>
            </Suspense>
          }
        />
      </Routes>
    </>
  )
}
