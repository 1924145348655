export type user = {
  name: string
}

export enum EventType {
  SignedUp = 'SIGNED_UP',
  AssignedToSite = 'ASSIGNED_TO_SITE',
  NewReferral = 'NEW_REFERRAL',
  //FilledQualificationForm = 'FILLED_QUALIFICATION_FORM',
  FailedContactAttempt = 'FAILED_CONTACT_ATTEMPT',
  UploadedImage = 'UPLOADED_IMAGE',
  SubjectEvaluatedSuitable = 'SUBJECT_EVALUATED_SUITABLE', // to be deleted
  SubjectEvaluatedNotSuitable = 'SUBJECT_EVALUATED_NOT_SUITABLE', // to be deleted

  //
  // this is not directly added - since we assigne the user to a site
  // meaning we put AssignedToSite on the user and create an event for the user
  //
  SubjectEligible = 'SubjectEligible',
  //
  // this is added after prescreening
  //
  SubjectNotEligeble = 'SubjectNotEligeble',

  SignedConsent = 'SIGNED_CONSENT',
  CancelSignedConsent = 'CANCEL_SIGNED_CONSENT',
  ReviewingInformation = 'REVIEWING_INFORMATION',
  ScheduledForScreening = 'SCHEDULED_FOR_SCREENING',
  CancelPlanedScreening = 'CANCEL_PLANED_SCREENING',
  ScheduledForRandomisation = 'SCHEDULED_FOR_RANDOMISATION',
  CancelPlanedRandomisation = 'CANCEL_PLANED_RANDOMISATION',
  SubjectRandomised = 'SUBJECT_RANDOMISED',
  CancelRandomisation = 'CANCEL_RANDOMISATION',
  Reengaged = 'REENGAGED',
  //SubjectRereferred = 'SUBJECT_REREFERRED',
  OtherEvent = 'OTHER_EVENT',
  //SubjectReferredToEvaluation = 'SUBJECT_REFERRED_TO_EVALUATION',
  NotReachedByEmail = 'NOT_REACHED_BY_EMAIL',
  NotReachedByPhone = 'NOT_REACHED_BY_PHONE',
  NotReached = 'NOT_REACHED',
  WaitingForReply = 'WAITING_FOR_REPLY',
  NoShow = 'NO_SHOW',
  Rescheduled = 'RESCHEDULED',
  FollowUp = 'FOLLOW_UP',
  TravelDistanceTooLong = 'TRAVEL_DISTANCE_TOO_LONG',
  OtherCommitments = 'OTHER_COMMITMENTS',
  RestrictiveWorkSchedule = 'RESTRICTIVE_WORK_SCHEDULE',
  NoLongerWantsToParticipate = 'NO_LONGER_WANTS_TO_PARTICIPATE',
  PrefersCurrentTreatment = 'PREFERS_CURRENT_TREATMENT',
  SubjectWithdrewConsent = 'SUBJECT_WITHDREW_CONSENT',
  TooManyFailedContactAttempts = 'TOO_MANY_FAILED_CONTACT_ATTEMPTS',
  OtherMotivationalDisqualification = 'OTHER_MOTIVATIONAL_DISQUALIFICATION',
  ConditionWasMisdiagnosed = 'CONDITION_WAS_MISDIAGNOSED',
  ConditionInsufficientlySevere = 'CONDITION_INSUFFICIENTLY_SEVERE',
  ConditionTooSevere = 'CONDITION_TOO_SEVERE',
  SymptomAreaOutOfRequiredRange = 'SYMPTOM_AREA_OUT_OF_REQUIRED_RANGE',
  SubjectAgeOutOfRequiredRange = 'SUBJECT_AGE_OUT_OF_REQUIRED_RANGE',
  OtherMedicalDisqualification = 'OTHER_MEDICAL_DISQUALIFICATION',
}

export type Project = {
  _id: string
  active: boolean
  projectRef: string
  title: string
  condition: string
  age: [low: number, high: number]
  sites: string[]
  questionnaires: string[]
  languages: LanguageISOCode[]
}

// See https://www.loc.gov/standards/iso639-2/php/English_list.php for specification, add as needed
// Not sure if something built in is smarter for this though...
export type LanguageISOCode = 'en' | 'es' | 'da'

export type AuditLogChangeReason = 
    | {
        type: 'ENTRY_ERROR'
      }
    | {
        type: 'DATA_UPDATED'
      }
    | {
        type: 'OTHER'
        comment: string
      }

