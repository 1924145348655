import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'
import reportWebVitals from './reportWebVitals'

import './styles/ant-compiled.css'
import './styles/styles.scss'

import Amplify from 'aws-amplify'
import awsconfig from './aws-exports'

const authConfig = {
  ...awsconfig,
  Auth: {
    ...awsconfig.Auth,
    storage: window.sessionStorage,
  },
}

Amplify.configure(authConfig)

//@ts-ignore
if (process.env.NODE_ENV !== 'production') {
  //@ts-ignore
  import(`./styles/size-debug.scss`).then(() => {})
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
