export const BASE_URL = process.env.REACT_APP_BASE_URL
export const DEBUG = process.env.REACT_APP_DEBUG === 'true' ? true : false
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ''

console.log(' constants > GOOGLE_MAPS_API_KEY = ', GOOGLE_MAPS_API_KEY)

export const COLOR_DANGER = '#dc3545'
export const COLOR_PLACEHOLDER = '#eaeaea'
export const COLOR_PRIMARY = '#4A6FA5'
export const COLOR_NOT_SELECTED = '#bbbbbb'

// export const QUESTION_TYPES: QuestionType[] = ['text', 'checkbox', 'radio', 'photo', 'number', 'slider', 'info']
// Only supported question types should appear in UI

export type PageType = {
  title: string
  to: string
  path: string
  visible: boolean
  gotoPage: (...rest) => string
}

export const FRONT_PAGE: PageType = {
  title: 'Front',
  path: `/`,
  to: `/`,
  visible: true,
  gotoPage: (projectId) => {
    return '/'
  },
}

export const SITES_PAGE: PageType = {
  title: 'Sites',
  path: `/sites`,
  to: `/sites`,
  visible: true,
  gotoPage: (projectId) => {
    return '/'
  },
}

export const PROJECT_PAGE: PageType = {
  title: 'Project Overview',
  path: `/project/:projectId`,
  to: `/project/:projectId`,
  visible: true,
  gotoPage: (projectId) => {
    return '/project/' + projectId
  },
}

export const STATS_PAGE: PageType = {
  title: 'Stats',
  path: `/stats/:projectId`,
  to: `/stats/:projectId`,
  visible: true,
  gotoPage: (projectId) => {
    return '/stats/' + projectId
  },
}

export const ASSIGNMENT_PAGE: PageType = {
  title: 'Assignment',
  path: `/assignment/:projectId`,
  to: `/assignment`,
  visible: true,
  gotoPage: (projectId) => {
    return '/assignment/' + projectId
  },
}

export const LOGIN = { title: 'Login', to: `/`, base: '/', visible: false }
export const LOGIN_NEW_PASSWORD = {
  title: 'New-password',
  path: `/new-password`,
  to: '/login/new-password',
  visible: false,
}
export const LOGIN_FORGOT_PASSWORD_SUBMIT = {
  title: 'submit-new-password',
  to: `/login/submit-new-password`,
  path: '/submit-new-password',
  visible: false,
}
export const LOGIN_FORGOT_PASSWORD = {
  title: 'forgot password',
  path: 'forgot-password',
  to: `/login/forgot-password`,
  visible: false,
}

export const LOGIN_MFA_CODE = { title: 'mfa', path: '/mfa', to: `/login/mfa`, visible: false }

export const PAGES = [FRONT_PAGE, ASSIGNMENT_PAGE]
export const SUB_PAGES = [PROJECT_PAGE, STATS_PAGE, ASSIGNMENT_PAGE]


//
// FOR AUTO LOGOUT
//

/** localStorage Key: When was the user last auto logged out? (number) */
export const LAST_AUTOLOGOUT_TIME_KEY = "lastAutoLogoutTime"

/** localStorage Key: What page was the user at, at last auto logout? */
export const LAST_AUTOLOGOUT_PAGE_KEY = "lastAutoLogOutPage"

/** localStorage Key: On tab reopen, we check if the user should sign in again. */
export const LAST_ACTIVITY_KEY = "lastActivityKey"

/** Should we redirect to the last page that was shown before autologout? (1 hour) */
export const LAST_AUTOLOGOUT_PAGE_EXPIRERY = 1000 * 60 * 60

/** Logs the user out after this time interval of inactivity. (15 min)*/
export const ACTIVITY_INTERVAL_TIME = 1000 * 60 * 15

/** How often do we want to check for inactivity. Also what the inactivity-countdown timer starts at. (1 min)*/
export const CHECK_ACTIVITY_INTERVAL_TIME = 1000 * 60
