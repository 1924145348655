/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 02/08/2022.
 */
import React, { useLayoutEffect, useState } from 'react'
import Drawer from 'antd/lib/drawer'
import { useNavigate, useParams } from 'react-router-dom'
import { useMoveEnrollment } from '../../hooks/useMoveEnrollment'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { BASE_URL, COLOR_PLACEHOLDER } from '../../constants'
import Button from 'antd/lib/button'
import { post } from '../../service/API'
import { getAssignmentsByProjectIdEndpoint } from '../../service/api-gateway'
import { useSWRConfig } from 'swr'
import { useJustificationModal } from '../../components/display/JustificationModal'
import { AuditLogChangeReason } from '../../Types'

const getBooleanValue = (value: boolean | null) => {
  if (value === null) {
    return 'User has not been asked'
  }

  return value ? 'yes' : 'no'
}

export const MoveEnrollment = () => {
  const navigate = useNavigate()
  const { enrollmentId, projectId } = useParams() as { enrollmentId: string; projectId: string }
  const { data, isError, isLoading, mutate } = useMoveEnrollment(enrollmentId)

  const [visible, setVisible] = useState(false)

  useLayoutEffect(() => {
    setVisible(true)
  }, [setVisible])

  const onClose = () => {
    setVisible(false)
  }

  const layoutChange = (e) => {
    if (!e) {
      //closeDrawer()
      mutate()
      navigate('../')
    }
  }

  console.log(' MoveEnrollment > data = ', data, isLoading)

  return (
    <Drawer
      title="Move Responder"
      destroyOnClose={true}
      width={'40%'}
      placement="right"
      onClose={onClose}
      visible={visible}
      bodyStyle={{ padding: 0 }}
      afterVisibleChange={layoutChange}
    >
      {data && !isError ? (
        <DrawerContent data={data} onClose={onClose} enrollmentId={enrollmentId} projectId={projectId}></DrawerContent>
      ) : (
        <SpinnerFullHeight></SpinnerFullHeight>
      )}
      {isError ? (
        <div className="">
          <h1>Error</h1>
        </div>
      ) : null}
    </Drawer>
  )
}

const DrawerContent = ({ data, projectId, enrollmentId, onClose }) => {
  const { mutate } = useSWRConfig()

  const moveClick = (siteId: string, justification: AuditLogChangeReason) => {
    const p = BASE_URL + '/dashboard/subject/move'
    const obj = { siteId: siteId, enrollmentId: enrollmentId, justification: justification }

    post(p, obj)
      .then((result) => {
        mutate(getAssignmentsByProjectIdEndpoint(projectId))
        onClose()
        //getProjectByIdEndpoint
        return result
      })
      .catch((error) => {
        console.log('error = ', error)
        return Promise.reject(error)
      })
  }

  return (
    <div className="container-fluid px-0">
      <div className="container px-4 py-4" style={{ background: COLOR_PLACEHOLDER }}>
        <div className="row" style={{}}>
          <div className="col-12">
            <p className="opacity-50">{data.enrollment._id}</p>
            <h5 className="fw-bold">{data.enrollment.displayName}</h5>
            <p className="">Project: {data.enrollment.project}</p>
            <p className="">state: {data.enrollment.stateType.replaceAll('_', ' ').toLowerCase()}</p>
            <p className="">willTravel: {getBooleanValue(data.enrollment.willTravel)}</p>
            <p className="">withinReach: {getBooleanValue(data.enrollment.withinReach)}</p>
          </div>
        </div>
      </div>
      <div className="container pt-3 px-3" style={{}}>
        <div className="row" style={{}}>
          <div className="col-12">
            <p className="mb-2">Available sites:</p>
          </div>
        </div>
        <div className="row" style={{}}>
          {data.sites.map((site, index) => {
            //
            // dont show current site
            //

            return (
              <SiteItem
                key={index}
                selected={data.enrollment.siteId === site._id}
                site={site}
                moveClick={moveClick}
              ></SiteItem>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const SiteItem = ({ site, moveClick, selected }) => {
  const clickker = () => {
    console.log("bør åbne justification modal..")
     useJustificationModal.getState().addJustification(true, (justification: AuditLogChangeReason) => {
       moveClick(site._id, justification)
     })
  }

  console.log(' MoveEnrollment > site = ', site)
  return (
    <div className="col-12 mb-3">
      <div
        className=" d-flex justify-content-between p-3 "
        style={{
          background: selected ? '#ffffff' : '#f6f6f6',
          boxShadow: selected ? '2px 4px 6px -2px rgba(74, 111, 165, 0.2)' : '2px 4px 5px -2px rgba(0, 0, 0, 0.1)',
          border: '1px solid #D6D6D6',
        }}
      >
        <div className="">
          <div className="ms-1 ">
            <div className="d-flex">
              <h5 className="mb-0">{site.officialName}</h5>
              {selected ? (
                <p className="ms-2 mb-0 text-muted" style={{ marginTop: 2 }}>
                  - Current site
                </p>
              ) : null}
              <div
                className="rounded-circle mt-2 ms-2"
                style={{ width: 10, height: 10, background: site.active ? 'green' : 'tomato' }}
              ></div>
            </div>
            <p className="p-small opacity-50">
              {site.internalName} - {site._id}
            </p>
          </div>
          <div className="d-inline-block rounded-pill  text-white px-2 mt-2" style={{ background: '#383838' }}>
            {Math.round(site.distanceToSite / 1000)} km to site
          </div>
        </div>
        <div className="align-self-end">
          {!selected ? (
            <Button onClick={clickker} type="primary">
              Move to this site{' '}
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  )
}
