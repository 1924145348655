import React, { useEffect, useState } from 'react'

import { authStore } from './store/auth/authStore'
import { SpinnerFullHeight } from './components/spinner/SpinnerFullHeight'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorView } from './components/error/ErrorView'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import { LoginPage } from './pages/login/LoginPage'
import { Main } from './Main'
import { JustificationModal } from './components/display/JustificationModal'
import VersionPage from './pages/version/VersionPage'

function App() {
  const user: any = authStore((state) => state.user)
  const init = authStore((state) => state.init)
  const [error, setError] = useState(null)

  console.log(' App > user = ', user)

  useEffect(() => {
    if (!user) {
      console.log(' App > run = ')
      init().catch((error: any) => {
        console.log(' App > init = ', error)
        setError(error)
      })
    }
  }, [init, user])

  if (!user && !error) {
    return <SpinnerFullHeight></SpinnerFullHeight>
  }

  const shouldRedirect = !user || user.challengeName

  return (
    <ErrorBoundary FallbackComponent={ErrorView}>
      {/*      {!user || user.challengeName ? <Login></Login> : <Main />}*/}
      <BrowserRouter>
        <Routes>
          <Route path="/login/*" element={<LoginPage />} />
          <Route path="/version/" element={<VersionPage />} />
          <Route path="/*" element={shouldRedirect ? <Navigate replace to="/login" /> : <Main />} />
        </Routes>
      </BrowserRouter>
      <JustificationModal />
    </ErrorBoundary>
  )
}

export default App
