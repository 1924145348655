import React from 'react'
import packageJson from '../../../package.json'

const VersionPage = () => (
  <main>
    <h1><br></br>Version: {packageJson.version}</h1>
  </main>
)

export default VersionPage
