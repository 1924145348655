import create, { State } from 'zustand'
import { Modal, Radio, RadioChangeEvent } from 'antd'
import { useState } from 'react'
import TextArea from 'antd/lib/input/TextArea'
import { AuditLogChangeReason } from '../../Types'

//
// Store for easily adding justications to submit functions.
//
export interface JustificationModalStore extends State {
  showModal: boolean
  onSubmit?: (justification: AuditLogChangeReason) => void
  addJustification: (requiresJustification: boolean, onSubmit: (justification: AuditLogChangeReason) => void) => void
  hideModal: () => void
}

export const useJustificationModal = create<JustificationModalStore>((set: any) => {
  return {
    showModal: false,

    addJustification: (requiresJustification: boolean, onSubmit: (justification: AuditLogChangeReason) => void) => {
      if (requiresJustification) {
        set({ showModal: true, onSubmit: onSubmit })
      } else {
        onSubmit({ type: 'DATA_UPDATED' })
      }
    },

    hideModal: () => {
      set({ showModal: false, onSubmit: undefined })
    },
  }
})

//
// Component that shows the modal where the justification is inputted
//
export function JustificationModal() {
  const showModal = useJustificationModal((state) => state.showModal)
  const onSubmit = useJustificationModal((state) => state.onSubmit)

  const [validationErr, setValidationErr] = useState<string | null>(null)
  const [radioValue, setRadioValue] = useState<number | null>(null)
  const [textValue, setTextValue] = useState<string | null>(null)

  const closeModal = () => {
    setValidationErr(null)
    useJustificationModal.getState().hideModal()
    setRadioValue(null)
    setTextValue(null)
  }

  const handleOk = () => {
    setValidationErr(null)

    if (!onSubmit) {
      console.log('JustificationModal >> No submit function provided!')
      return
    }

    switch (radioValue) {
      case null:
        setValidationErr('Please select a reason...')
        break
      case 1:
        onSubmit({ type: 'ENTRY_ERROR' })
        closeModal()
        break
      case 2:
        onSubmit({ type: 'DATA_UPDATED' })
        closeModal()
        break
      case 3:
        if (!textValue) {
          setValidationErr('Please write a reason...')
        } else {
          onSubmit({ type: 'OTHER', comment: textValue })
          closeModal()
        }
        break
    }
  }

  const onChangeRadio = (e: RadioChangeEvent) => {
    setRadioValue(e.target.value)
    setValidationErr(null)
  }

  const onChangeText = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTextValue(e.target.value)
  }

  return (
    <Modal
      title="What is the justification for this update?"
      visible={showModal}
      onOk={handleOk}
      onCancel={closeModal}
      style={{ zIndex: 1500 }}
      zIndex={1500}
    >
      <Radio.Group onChange={onChangeRadio} value={radioValue}>
        <Radio value={1}>Entry Error</Radio>
        <Radio value={2}>Data Updated</Radio>
        <Radio value={3}>Other</Radio>
      </Radio.Group>
      {radioValue === 3 && (
        <TextArea
          rows={3}
          placeholder="Please write the justification here..."
          style={{ marginTop: 20 }}
          onChange={onChangeText}
        />
      )}
      {validationErr && <ValidationError txt={validationErr}></ValidationError>}
    </Modal>
  )
}

const ValidationError = ({ txt = 'add text' }: { txt: string }) => {
  return (
    <div className="col-12 d-flex">
      <p className="mb-0 mt-1  ml-2 text-danger">{txt}</p>
    </div>
  )
}
