import { Modal } from 'antd'
import Countdown from 'antd/lib/statistic/Countdown'
import { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { LAST_AUTOLOGOUT_PAGE_KEY, LAST_AUTOLOGOUT_TIME_KEY } from '../../constants'
import { authStore } from '../../store/auth/authStore'

const minute = 1000 * 60
const timeout = 15 * minute
const promptBeforeIdle = 1 * minute

export default function AutoLogOutModal() {
  const [remaining, setRemaining] = useState<number>(timeout)
  const [open, setOpen] = useState<boolean>(false)

  const onIdle = () => {
    console.log('logging out...')

    // local store data that is used to determine on log in, if the user should be redirectly to the last active page.
    const pathname = window.location.pathname
    const tabPathName = pathname.split('/').slice(0, 4).join('/')
    window.localStorage.setItem(LAST_AUTOLOGOUT_TIME_KEY, Date.now().toString())
    window.localStorage.setItem(LAST_AUTOLOGOUT_PAGE_KEY, tabPathName)

    authStore.getState().logout()
  }

  const onActive = () => {
    setOpen(false)
  }

  const onPrompt = () => {
    setOpen(true)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
    startOnMount: true,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
    ],
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleStillHere = () => {
    console.log('User still here. Dont log out.')
    activate()
  }

  return (
    <Modal onCancel={handleStillHere} title="Are you there?" zIndex={99999} visible={open} footer={[]}>
      <Countdown title="Logging out in.." value={Date.now() + remaining * 1000} />
    </Modal>
  )
}
